

.BalanceDayRecords{
  .analysis{
    margin: 25px 0;
    color: #000;
    .analysis-item{
      display: inline-block;
      // &+.analysis-item{
      //   margin-left: 48px;
      // }
      margin-right: 48px;
    }
  }
}