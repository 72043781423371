.OrderDetail{
  color: #8c8c8c;
  
  .ant-card-head{
    padding: 0;
  }
  .box{
    border: 0 none;
    padding: 0;
    .ant-card-body{
      padding-left: 0;
      padding-right: 0;
    }
    .info{
      display: flex;
      flex-wrap: wrap;
      .item {
        line-height: 32px;
        margin-bottom: 16px;
        .key{
          padding-right: 8px;
          display: inline-block;
          width: 130px;
          text-align: right;
          margin-left: 24px;
        }
        .value{
          padding: 0 8px;
          display: inline-block;
          width: 260px;
          background: #f5f5f5;
        }
      }
    }
    
  }

  .carImg{
    width: 160px;
    height: auto;
  }
}

.OrderDetail-img-modal{
  .ant-modal-close-x{
    width: 26px;
    height: 26px;
    line-height: 26px;
  }
}