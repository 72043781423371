@import '../../theme.less';
.UserMenu{
  float:right;
  color: rgba(255,255,255,.7);
  font-size: 14px;
  padding-right: 6px;
  
}
.userMenu-box{
  
  .ant-dropdown-menu-item{
    background: @menu-dark-bg;
    color: rgba(255,255,255,.7);
    a{
      color: rgba(255,255,255,.7);
    }
  }
  .ant-dropdown-menu-item-active{
    background: @menu-dark-item-active-bg;
  }
}
