.MonthRangePicker{
  width: 300px;
  display: flex;
  .MonthRangePicker__item.ant-calendar-picker{
    flex: 1;
    width: auto!important;
    &:first-child{
      margin-right: 1em;
    }
    .ant-calendar-picker-input{
      width: 100%;
    }
  }
}