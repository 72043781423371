.BalanceRecords{
  .list{
    .ant-table-thead{
      div:nth-child(1){
        text-align: right;
      }
      div:nth-child(2){
        text-align: right;
      }
      
      
    }
    
    .ant-table-thead{
      th{
        padding-top: 6px;
        padding-bottom: 6px;
      }
      th.sub-column{
        padding-top: 0;
        padding-bottom: 10px;
        vertical-align: text-top;
        
      }
      .sub-column{
        &:nth-child(6){
          padding-right: 45px;
        }
        &:nth-child(10){
          padding-right: 45px;
        }
      }
    }

    .ant-table-tbody{
      td.sub-column{
        &:nth-child(8){
          padding-right: 45px;
        }
        &:nth-child(12){
          padding-right: 45px;
        }
      }
    }
    
    
  }
}