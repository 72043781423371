.ChargeReportDetail-Table{
  width: 100%;
  border: 1px solid #eaeaea;
  th{
    border: 1px solid #eaeaea;
    text-align: center;
    line-height: 32px;
    font-weight: normal;
    font-size: 14px;
    background: #fafafa;
  }
  td{
    border: 1px solid #eaeaea;
    text-align: center;
    line-height: 32px;
    font-weight: normal;
    font-size: 14px;
    
  }
}