.OrderRecords{
  .content .search .ant-select{
    width: 332px;
  }
  .content .list .ant-table-scroll .ant-table-tbody td:last-child *,  .content .list .ant-table-scroll .ant-table-thead tr:first-child th:last-child span{
    visibility:visible;
  }
  .ant-table-row-level-1{
    color: red;
  }
}