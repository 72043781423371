@import "../../theme.less";
.Page{
  min-height: 100vh;
  &>.Header{
    background: #1f83e6;
    height: 50px;
    line-height: 50px;
    padding: 0 18px;
    .logo{
      height: 30px;
      width: auto;
      display: inline;
      box-sizing: content-box;
      vertical-align: top;
      padding-top: 8px;
    }    
    .title{
      font-size: 18px;
      color: #fff;
      line-height: 50px;
    }
  }
  .trigger{
    height: 54px;
    line-height: 54px;
    background: @layout-trigger-background;
    padding-right: 18px;
    text-align: right;
    width: 100%;
    color: @layout-trigger-color;
  }
  .ant-menu-inline-collapsed{
    width: 52px;
  }
  .ant-menu-item, .ant-menu-submenu-title {
    white-space: normal;
    
  } 
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title{
    padding: 0 18px!important;
  }
  
}

.page-content{
  .header{
    margin-bottom: 24px;
    .ant-card-body{
      padding: 0 24px;
    }
    .title{
      font-size: 18px;
      font-weight: bold;
      line-height: 50px;
      display: inline-block;
    }
    .extra{
      display: inline-block;
      padding: 9px 0 0 70px;
      font-size: 14px;
    }
    .goback{
      &+.title{
        margin-left: 24px;
      }
    }
  }
  .content{
    margin: 0 24px;
    .search{
      .ant-select{
        width: 300px;
      }
      .ant-input{
        width: 300px;
      }
      .ant-calendar-picker-input{
        width: auto;
      }
      .ant-btn{
        margin: 4px 10px 0 0;
        vertical-align: middle;
      }
    }
    .export{
      margin: 10px 0;
      float: right;
    }
    .list{
      margin-top: 20px;
      table{
        font-size: 14px;
      }
      .ant-table-thead{
        th{
          white-space: nowrap;
        }
      }
      .ant-table-tbody{
        td{
          white-space: nowrap;
        }
      }
      .ant-table-scroll{
      
        .ant-table-thead{
          tr:first-child{
            th:last-child{
            
              span {
                visibility: hidden;
              }
              
            }
          }
          
        }
        .ant-table-tbody{
          td:last-child{
            
            * {
              visibility: hidden;
            }
            
          }
        }
      }
    }
  }
}