

.login-form {
  max-width: 380px;
  padding-top: 76px;
  .ant-input{
    height: 40px;
    line-height: 40px;
    min-height: 40px;
    width: 100%;
  }

  .ant-btn{
    height: 40px;
    line-height: 40px;
  }
  .code{
    
  }
  .get-code{
    line-height: 40px;
    height: 40px;
    float:right;
  }
  .title{
    padding-bottom: 40px;
    text-align: center;
    color: #8c8c8c;
    font-size: 16px;
  }
}
.login-form-forgot {
  padding-top: 20px;
  float: right;
}
.login-form-button {
  width: 100%;
}

.login-form-code{
  height: 40px;
  width: auto;
  float:right;
}

.login-page{
  min-width: 1200px;
  overflow: auto;
  .header{
    height: 84px;
    background: #1280e9;
    line-height: 84px;
    display: flex;
    align-items: center;
    .logo{
      padding-left: 300px;
    }
    span{
      font-size: 22px;
      color: #fff;
      line-height: 40px;
      vertical-align: middle;
      display: inline-block;
      height: 22px;
    }
    img{
      vertical-align: middle;
      display: inline-block;
    }

    
  }
  .main{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    .banner{
      padding-top: 136px;
      padding-right: 95px;
    }
  }

  .copyright{
    font-size: 14px;
    color: #a6a6a6;
    padding-bottom: 32px;
    text-align: center;
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
  }
}