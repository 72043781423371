.LoginLogs {
  .ant-calendar-picker {
    width: 300px !important;
  }

  .content
    .list
    .ant-table-scroll
    .ant-table-thead
    tr:first-child
    th:last-child
    span {
    visibility: visible;
  }
}
