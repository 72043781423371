.ChargeReportsDetail {
  .ant-table,.ant-card-body{
    font-family: "宋体";
  }
  .Report-title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    overflow: hidden;
    .ant-btn {
      float: right;
    }
  }
  .Report-footer{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .list {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 6px;
    }
    table {
      border-collapse: collapse;
    }
    th,
    td {
      border: 1px solid #ccc;
    }
    .ant-table-thead {
      div:nth-child(2) {
        text-align: center;
      }
    }

    .ant-table-thead {
      th {
        background: #eee;
        padding-top: 6px;
        padding-bottom: 6px;
      }
      th.sub-column {
        padding-top: 0;
        padding-bottom: 10px;
        vertical-align: text-top;
      }
      .sub-column {
        &:nth-child(4) {
          padding-right: 45px;
        }
        &:nth-child(8) {
          padding-right: 45px;
        }
      }
    }

    .ant-table-tbody {
      td.sub-column {
        &:nth-child(6) {
          padding-right: 45px;
        }
        &:nth-child(10) {
          padding-right: 45px;
        }
      }

      .ant-table-row {
        &:last-child {
          background: #eee;
        }
        &:first-child {
          background: #fff;
        }
      }
    }
  }

  .amount {
    text-align: left;
  }
  .money {
    text-align: left;
  }
}
// .ChargeReportsDetail-batch {
//   &.list {
//     .ant-table-tbody {
//       .ant-table-row {
//         &:last-child {
//           background: #fff;
//         }
//       }
//     }
//   }
// }
